import type { ControllerFlowAPI, ILocation } from '@wix/yoshi-flow-editor';
import type { PopulatedMenu } from 'root/apiTypes';
import type { NavigateToParams } from 'root/clientTypes';

const createCleanLink = (url: string) => {
  const link = new URL(url);
  link.searchParams.delete('menu');
  link.searchParams.delete('menuId');
  return link;
};

export const buildMenuUrlFromCurrentUrl = ({ isFirst, urlQueryParam, menuId }: NavigateToParams) => {
  const link = createCleanLink(window.location.href);
  if (isFirst) {
    return link.toString();
  }

  if (urlQueryParam) {
    link.searchParams.set('menu', urlQueryParam);
  } else if (menuId) {
    link.searchParams.set('menuId', menuId);
  } else {
    throw new Error('urlQueryParam or menuId must be provided');
  }

  return link.toString();
};

export const goToPage = (flowAPI: ControllerFlowAPI) => {
  return ({ isFirst, urlQueryParam, menuId }: NavigateToParams) => {
    const { location } = flowAPI?.controllerConfig?.wixCodeApi;
    if (!location) {
      return;
    }

    if (isFirst) {
      location.queryParams.remove(['menu']);
      return;
    }
    if (urlQueryParam) {
      location.queryParams.add({ menu: urlQueryParam });
    } else if (menuId) {
      location.queryParams.add({ menuId });
    } else {
      throw new Error('menu.urlQueryParam or menu.id must be provided');
    }
  };
};

export const getMenuToDisplayFromUrl = ({
  urlQuery,
  displayableMenus,
}: {
  urlQuery: ILocation['query'];
  displayableMenus: PopulatedMenu[];
}) => {
  const menuQueryParam = urlQuery.menu;
  const menuId = urlQuery.menuId;

  if (!menuQueryParam && !menuId) {
    return 0;
  }

  const menuToDisplayIndex = displayableMenus.findIndex((menu: PopulatedMenu) =>
    menuQueryParam ? menu.urlQueryParam === menuQueryParam : menu.id === menuId
  );

  return menuToDisplayIndex;
};
