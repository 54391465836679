import {
  LABEL_TITLE_COLUMNS_MAX_SIZE,
  LABEL_TITLE_GRID_MAX_SIZE,
} from 'root/components/Menus/Settings/Tabs/DesignTab/DesignSections/LabelsDesign/consts';

export enum Preset {
  Columns = 0,
  Grid = 1,
  Center = 2,
  Strip = 3,
}

export const getPresetName = (preset: Preset) => {
  switch (preset) {
    case Preset.Grid:
      return 'grid';
    case Preset.Columns:
      return 'columns';
    case Preset.Center:
      return 'center';
    case Preset.Strip:
      return 'strip';
    default:
      return '';
  }
};

export const hasHorizontalImageOptions = (preset: Preset) => preset === Preset.Grid;
export const hasImageByDefault = (preset: Preset) => preset === Preset.Grid;

export const hasSectionTopDivider = (preset: Preset) => [Preset.Grid, Preset.Center].includes(preset);
export const hasSectionBottomDivider = (preset: Preset) => preset === Preset.Grid;

export const hasItemDivider = (preset: Preset) => [Preset.Columns, Preset.Strip].includes(preset);
export const hasItemImage = (preset: Preset) => [Preset.Grid, Preset.Columns, Preset.Strip].includes(preset);

export const hasPriceVariantDividers = (preset: Preset) => preset === Preset.Center;

export const getMaxLabelFontSize = (preset: Preset) =>
  preset === Preset.Grid ? LABEL_TITLE_GRID_MAX_SIZE : LABEL_TITLE_COLUMNS_MAX_SIZE;
