import type { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { PopulatedMenuClient } from 'root/api/PopulatedMenuClient';
import { getMonitoredApiCall } from 'root/api/utils/monitoredApiCall';
import type { ApiCallResult, GetAllResponse } from 'root/api/utils/types';
import type { PopulatedMenu } from 'root/apiTypes';
import { getPriceFormatter } from 'root/utils/currency';
import { goToPage } from 'root/utils/navigation';
import { WarmupDataManager } from 'root/utils/WarmupDataManager';
import { restaurantsUouPageFinishedLoading } from '@wix/bi-logger-restaurants-data/v2';
import { getHostingEnvironment, getIsCurrentPageMenusDefaultPage } from 'root/utils/bi';
import MenuWidget from 'root/components/Menus/.component.json';
import { MENU_WIDGET_NAME, PAGE_NAME } from 'root/consts';
import { getPopulatedMenusWithFormattedPrice } from 'root/utils/getPopulatedMenusWithFormattedPrice';
import settingsParams from './settingsParams';
import { sendSEOData } from 'root/utils/seo';
import type { MenuOrder } from './Settings/Tabs/MenusTab/MenusSettingsParams';

let menusPromise: ApiCallResult<GetAllResponse<PopulatedMenu>> | undefined;

const createController: CreateControllerFn = ({ flowAPI }) => {
  const priceFormatter = getPriceFormatter(flowAPI);
  const navigateTo = goToPage(flowAPI);

  const warmupData = new WarmupDataManager(
    flowAPI.controllerConfig.wixCodeApi.window.warmupData,
    flowAPI.environment.isSSR
  );
  const { environment, settings } = flowAPI;
  const { site, location, seo } = flowAPI?.controllerConfig?.wixCodeApi;
  const monitoredApiCall = getMonitoredApiCall({
    fedopsLogger: flowAPI.fedops,
    sentry: flowAPI.sentry,
  });
  const getMonitoredPopulatedMenuClient = () =>
    monitoredApiCall(() => PopulatedMenuClient(flowAPI.essentials.httpClient).getAll({}), 'get-populate-menus');

  if (!menusPromise) {
    menusPromise = warmupData.manageData(getMonitoredPopulatedMenuClient, 'populatedMenus');
  }
  return {
    async pageReady() {
      const isCurrentPageMenusDefaultPage = await getIsCurrentPageMenusDefaultPage(site);
      const menusDisplayOrder = settings.get(settingsParams.menusDisplayOrder!) as MenuOrder[];

      if (menusPromise) {
        const { data: menus, error } = (await menusPromise) || {};
        flowAPI.controllerConfig.setProps({
          menus: menus?.data ? getPopulatedMenusWithFormattedPrice(menus.data, priceFormatter) : [],
          navigateTo,
          error,
          urlQuery: location.query,
        });

        if (menus && menus.data.length > 0) {
          sendSEOData({
            baseUrl: location.baseUrl,
            siteUrl: site.currentPage?.url ?? '',
            isCurrentPageMenusDefaultPage,
            multilingual: environment.multilingual,
            menusDisplayOrder,
            menus: menus.data,
            seo,
          });
        }
      }

      flowAPI.bi?.report(
        restaurantsUouPageFinishedLoading({
          hosting: getHostingEnvironment(flowAPI.environment),
          pageName: PAGE_NAME,
          product: PAGE_NAME,
          widgetId: MenuWidget.id,
          widgetInstanceId: flowAPI.controllerConfig.compId,
          widgetName: MENU_WIDGET_NAME,
          isMenusDefaultPage: isCurrentPageMenusDefaultPage,
          isOoi: true,
        })
      );
    },
  };
};

// eslint-disable-next-line import/no-default-export
export default createController;
