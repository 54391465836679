import { SettingsParamType, createSettingsParams } from '@wix/tpa-settings';
import type { ItemImage } from 'root/apiTypes';
import { DEFAULT_PLACEHOLDER_IMAGE } from 'root/assets/images';

export type IDisplaySettingsParams = {
  checkImagePlaceholderMenus?: SettingsParamType.Object;
  checkPricingOptionsMenus?: SettingsParamType.Object;
  imagePlaceholder: SettingsParamType.Object;
  showZeroPricingForAllMenus: SettingsParamType.Boolean;
  showItemImagePlaceholder: SettingsParamType.Boolean;
  showImagePlaceholderForAllMenus: SettingsParamType.Boolean;
};

export type MenuOrder = { id: string; checked: boolean };

export const DisplaySettingsParams = createSettingsParams<IDisplaySettingsParams>({
  checkImagePlaceholderMenus: {
    type: SettingsParamType.Object,
    getDefaultValue: () => undefined,
  },
  checkPricingOptionsMenus: {
    type: SettingsParamType.Object,
    getDefaultValue: () => undefined,
  },
  imagePlaceholder: {
    type: SettingsParamType.Object,
    getDefaultValue: () =>
      ({
        url: DEFAULT_PLACEHOLDER_IMAGE,
      } as ItemImage),
  },
  showZeroPricingForAllMenus: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showItemImagePlaceholder: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showImagePlaceholderForAllMenus: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
});
